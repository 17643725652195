import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Logo from '@/assets/fortressLogo.svg'
import { Button } from '@/components/StyledComponents/Button'
import useWindowSize from '@/hooks/useWindowSize'
import { Footer } from '@/components/Footer'

const Wrapper = styled.div<{ height: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightGray};
  height: ${({ height }) => height};
  margin-top: -100px;
`
const LogoStyle = styled.div`
  display: flex;
  img {
    width: 100%;
  }
`
const Text = styled.p`
  margin-block: 40px 20px;
  max-width: 250px;
`

const NotFoundPage: React.FC = () => {
  const { height } = useWindowSize()

  return (
    <Layout>
      <Wrapper height={`${height}px`}>
        <div>
          <StaticImage
            src={'../assets/404.png'}
            alt="lightHouse"
            width={300}
            placeholder={'blurred'}
          />
        </div>
        <div>
          <LogoStyle>
            <Link to="/">
              <img src={Logo} alt={'logo'} />
            </Link>
          </LogoStyle>
          <Text>We couldn’t find the page you’re looking for…</Text>
          <Link to={'/'}>
            <Button primary>Back to Homepage</Button>
          </Link>
        </div>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage
